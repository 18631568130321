import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledSmallRadioButton = styled(Box)`
  width: calc(50% - 8px);

  ${props =>
    props.isColumn &&
    `
    width: 100%;
  `}

  .radio-buttons {
    &-small-button {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;
      border: 1px solid #d5dadf;
      border-radius: 8px;
      background-color: #ffffff;
      padding: 8px 16px 8px 16px;
      margin: 0;

      & .MuiRadio-root {
        padding: 4px;
      }

      &.checked {
        border: 2px solid #bb945c;
      }

      &.mostPopular {
        border: 1px solid;
        border-color: rgba(187, 148, 92, 0.5);

        &.checked {
          border: 2px solid #bb945c;
        }
      }
    }

    &-label-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0;
    }

    &-small-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1d1d1d;
    }

    &-most-popular {
      position: absolute;
      top: -10px;
      right: 12px;
      height: 18px;
      width: 86px;
      border-radius: 4px;
      padding: 0px 6px 0px 6px;
      background-color: #bb945c;
      color: #ffffff;
      font-weight: 450;
      font-size: 12px;
      line-height: 18px;
    }
  }
`
