import { FormControl } from '@mui/material'

import styled from 'styled-components'

export const StyledRadioButtonGroup = styled(FormControl)`
  display: flex;

  .radio-buttons {
    &-label-box {
      display: flex;
      align-items: center;
    }

    &-label {
      cursor: pointer;
      font-weight: 450;
      font-size: 14px;
      line-height: 21px;
      color: #6f6f6f;
    }

    &-group {
      gap: 8px;

      &.isSmall {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        flex-direction: row;
      }

      &.isColumn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 16px;
      }

      &.isMostPopular {
        margin-top: 8px;
      }
    }

    &-content-tooltip {
      padding: 0;
      margin-left: 9px;
    }
  }

  .sold-out {
    .radio-buttons-small-button {
      background-color: #fff;

      & .MuiTypography-root {
        display: block;
      }

      & .MuiFormControlLabel-label {
        display: block;
        width: 100%;
      }
    }
  }
`
