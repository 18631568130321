import React, { useState } from 'react'
import { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material'

import CalendarIcon from '../../assets/icons/CalendarIcon'
import ListIcon from '../../assets/icons/ListIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import CustomButton from '../../components/Button'
import DateModal from '../../components/DateModal'
import Discount from '../../components/Discount'
import Form from '../../components/Form'
import List from '../../components/List'
import RadioButtonGroup from '../../components/RadioButtonGroup'
import Select from '../../components/Select'
import Summary from '../../components/Summary'
import Input from '../../components/TextField'
import Toast from '../../components/Toast'
import {
  addPackage,
  addProgramDate,
  addTransferDetails,
  getProgramDatesByProductOrder,
  getSubjectsByOrder,
  selectChildSubject,
  selectPreferredChildMonths,
  selectPreferredMonths,
  validateProductOrder
} from '../../store/enrolment/enrolment.actions'
import {
  selectActiveProductOrderId,
  selectIsChangeModalOpen,
  selectProductOrders,
  selectProgramDates,
  selectSubjectsByOrder
} from '../../store/enrolment/enrolment.selectors'
import {
  setActiveProductOrderId,
  setIsMainProductSaved,
  setStartPathwayScreen
} from '../../store/enrolment/enrolment.slice'
import { Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

let toastId = null

const StartEducationalPathwayScreen = () => {
  const productOrders = useSelector(selectProductOrders)
  const activeProductOrderId = useSelector(selectActiveProductOrderId)
  const subjectsByOrder = useSelector(selectSubjectsByOrder)
  const programDates = useSelector(selectProgramDates)
  const isChangeModalOpen = useSelector(selectIsChangeModalOpen)
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [classifications, setClassifications] = useState(null)
  const [dates, setDates] = useState([])
  const [step, setStep] = useState(0)
  const [flow, setFlow] = useState(null)
  const [classificationValue, setClassificationValue] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [transferOptions, setTransferOptions] = useState(null)
  const [subject, setSubject] = useState(null)
  const [parentMonths, setParentMonths] = useState(null)
  const [months, setMonths] = useState(null)
  const [transferOptionsData, setTransferOptionsData] = useState([])
  const [location, setLocation] = useState(null)
  const [isOpenDateModal, setIsOpenDateModal] = useState(false)
  const [productOrderId, setProductOrderId] = useState(activeProductOrderId || productOrders[0].id)
  const [activeProductOrder, setActiveProductOrder] = useState(
    productOrders.find(item => item.id === activeProductOrderId) || productOrders[0]
  )
  const [isLoading, setIsLoading] = useState(false)

  const [showTimeAlert, setShowTimeAlert] = useState(false)

  const handleOpenDateModal = () => setIsOpenDateModal(isOpenDateModal => !isOpenDateModal)

  useEffect(() => {
    const activeProductOrder = productOrders.find(item => item.id === activeProductOrderId) || productOrders[0]

    dispatch(setActiveProductOrderId(activeProductOrder.id))

    const classifications = activeProductOrder?.product?.packages?.map(item => {
      const classificationProduct = productOrders?.find(p => p?.displayedPackage?.id === item?.id)

      const updatedTextArray = item?.listOfFeatures.map((text, index) => {
        return <span key={index} dangerouslySetInnerHTML={{ __html: text }} />
      })

      return {
        value: item.id,
        label: item.classification.title,
        isDiscount: !!item.discount,
        discount: item.discount,
        tooltipWidth: 455,
        info: item.packageTitle,
        duration: item.description,
        discountPrice: classificationProduct?.childProductOrder?.price?.subtotal
          ? Math.round(
              (classificationProduct?.price?.subtotal || 0) +
                (classificationProduct?.childProductOrder?.price?.subtotal || 0)
            )
          : item.discountPrice,
        price: classificationProduct?.childProductOrder?.price?.programFee
          ? Math.round(
              (classificationProduct?.childProductOrder?.price?.programFee || 0) +
                (classificationProduct?.price?.programFee || 0)
            )
          : item.price,
        tooltip: (
          <List
            header={item.featuresTitle}
            options={updatedTextArray.map(item => ({
              component: <Typography className='list-text'>{item}</Typography>
            }))}
          />
        )
      }
    })

    const transferOptionsData = [
      {
        label: 'Pick-up + Return',
        value: 'Pick-up + Return',
        transferFrom: true,
        transferTo: true,
        mostPopular: true,
        price:
          activeProductOrder?.location?.airportTransferPrice && activeProductOrder?.location?.airportTransferPrice * 2
      },
      {
        label: 'Pick-up only',
        value: 'Pick-up only',
        transferFrom: true,
        transferTo: false,
        mostPopular: false,
        price: activeProductOrder?.location?.airportTransferPrice
      },
      {
        label: 'Return only',
        value: 'Return only',
        transferFrom: false,
        transferTo: true,
        mostPopular: false,
        price: activeProductOrder?.location?.airportTransferPrice
      },
      {
        label: 'No (maybe later)',
        value: 'No (maybe later)',
        transferFrom: false,
        transferTo: false,
        mostPopular: false
      }
    ]

    const selectedTransferOption = transferOptionsData.find(
      item =>
        item.transferFrom === activeProductOrder?.transferFrom && item.transferTo === activeProductOrder?.transferTo
    )?.value

    const selectedSubject = activeProductOrder?.childProductOrder?.subject?.title
    const selectedMonths = activeProductOrder?.childProductOrder?.preferredMonths?.map(
      item => `${item.month} ${item.year}`
    )
    const selectedParentMonths = activeProductOrder?.preferredMonths?.map(item => `${item.month} ${item.year}`)

    setProductOrderId(activeProductOrder?.id)
    setClassifications(classifications)
    setDateValue(activeProductOrder?.programDate?.id)
    setClassificationValue(activeProductOrder?.displayedPackage?.id)
    setFlow(activeProductOrder?.childProductOrder ? 'bundle' : 'classic')
    setTransferOptions(selectedTransferOption)
    setTransferOptionsData(transferOptionsData)
    setLocation(activeProductOrder?.location?.title)
    setSubject(selectedSubject)
    setMonths(selectedMonths)
    setParentMonths(selectedParentMonths)
  }, [activeProductOrder, activeProductOrderId, productOrders])

  useEffect(() => {
    if (programDates) {
      const dates = programDates?.map(item => {
        return {
          label: item.title,
          value: item.id,
          isSoldOut: item.isSoldOut
        }
      })
      setDates(dates)
    }
  }, [dispatch, programDates])

  useEffect(() => {
    if (activeProductOrder?.childProductOrder?.id) {
      dispatch(getSubjectsByOrder({ productOrderId: activeProductOrder?.childProductOrder?.id }))
    }
    if (activeProductOrder?.package?.id) {
      dispatch(getProgramDatesByProductOrder({ productOrderId: activeProductOrder.id })).then(({ payload }) => {
        const dates = payload
          ? payload.map(item => {
              return {
                label: item.title,
                value: item.id,
                isSoldOut: item.isSoldOut
              }
            })
          : null
        setDates(dates)
      })
    }
  }, [])

  useEffect(() => {
    if (activeProductOrderId && !productOrders.find(item => item.id === activeProductOrderId)) {
      dispatch(getProgramDatesByProductOrder({ productOrderId: productOrders[0]?.id })).then(({ payload }) => {
        const dates = payload
          ? payload.map(item => {
              return {
                label: item.title,
                value: item.id,
                isSoldOut: item.isSoldOut
              }
            })
          : null
        setDates(dates)
      })
    }
  }, [productOrders?.length])

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      classification: classificationValue,
      dates: dates,
      transferOptions: transferOptionsData,
      subject: null,
      months: []
    }
  })

  const { field: classificationField } = useController({
    name: 'classification',
    control: control
  })

  const { field: datesField } = useController({
    name: 'dates',
    control: control
  })

  const { field: transferOtionsField } = useController({
    name: 'transferOptions',
    control: control
  })

  const { field: subjectField } = useController({
    name: 'subject',
    control: control
  })

  const { field: monthsField } = useController({
    name: 'months',
    control: control
  })

  const { field: parentMonthsField } = useController({
    name: 'parentMonths',
    control: control
  })

  const handleClassificationValueChange = event => {
    setClassificationValue(event.target.value)
    classificationField.onChange(event.target.value)
    setIsLoading(true)
    dispatch(
      addPackage({
        productOrderId,
        body: JSON.stringify({ packageId: event.target.value }),
        callback: () => {
          setStep(1)
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = payload?.productOrders?.find(item => item.id === productOrderId)
        dispatch(setActiveProductOrderId(activeProductOrder.id))
        setActiveProductOrder(activeProductOrder)
        setFlow('classic')
        dispatch(getProgramDatesByProductOrder({ productOrderId: activeProductOrder.id }))
          .then(({ payload }) => {
            const dates = payload
              ? payload?.map(item => {
                  return {
                    label: item.title,
                    value: item.id,
                    isSoldOut: item.isSoldOut
                  }
                })
              : null
            setDates(dates)
          })
          .then(() => setIsLoading(false))
          .catch(() => {})
        if (activeProductOrder?.childProductOrder) {
          dispatch(getSubjectsByOrder({ productOrderId: activeProductOrder?.childProductOrder?.id })).catch(() => {})
          setFlow('bundle')
        }
      })
      .catch(() => {})
  }

  const handleDateValueChange = event => {
    setDateValue(event.target.value)
    datesField.onChange(event.target.value)
    dispatch(
      addProgramDate({
        productOrderId,
        body: JSON.stringify({ programDateId: event.target.value }),
        callback: () => {
          setStep(2)
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = payload.productOrders.find(item => item.id === productOrderId)
        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {})
  }

  const handleTransferOptionsChange = event => {
    const option = transferOptionsData.find(item => item.value === event.target.value)
    setTransferOptions(option.value)
    transferOtionsField.onChange(option)
    dispatch(
      addTransferDetails({
        productOrderId,
        body: JSON.stringify({ transferTo: option.transferTo, transferFrom: option.transferFrom }),
        callback: () => {
          setStep(3)
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = payload.productOrders.find(item => item.id === productOrderId)
        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {})
  }

  const handleSubjectChange = value => {
    const subjectId = subjectsByOrder.find(item => item.title.replace(/&amp;/g, '&') === value).id
    setSubject(value)
    subjectField.onChange(subjectId)
    dispatch(
      selectChildSubject({
        productOrderId,
        body: JSON.stringify({ subjectId: subjectId }),
        callback: () => {
          if (step <= 4) {
            setStep(4)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = payload?.productOrders?.find(item => item.id === productOrderId)

        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {})
  }

  const handleMonthsSubmit = (currentYearMonths, nextYearMonths) => {
    const currentYearValues = currentYearMonths.filter(item => item.selected).map(item => item.value)
    const nextYearValues = nextYearMonths.filter(item => item.selected).map(item => item.value)
    const inputValues = [...currentYearValues, ...nextYearValues]
    const values = [
      ...currentYearValues.map(item => ({ month: item.slice(0, 3), year: +item.slice(4, 8) })),
      ...nextYearValues.map(item => ({ month: item.slice(0, 3), year: +item.slice(4, 8) }))
    ]
    setStep(flow === 'classic' ? 2 : 5)
    handleOpenDateModal()
    if (flow === 'classic') {
      dispatch(
        selectPreferredMonths({
          productOrderId,
          body: JSON.stringify({ preferredMonths: values })
        })
      )
        .then(({ payload }) => {
          const activeProductOrder = payload?.productOrders?.find(item => item.id === productOrderId)
          setActiveProductOrder(activeProductOrder)
          setParentMonths(inputValues)
          parentMonthsField.onChange(inputValues)
        })
        .catch(() => {})
    } else {
      dispatch(
        selectPreferredChildMonths({
          productOrderId,
          body: JSON.stringify({ preferredMonths: values })
        })
      )
        .then(({ payload }) => {
          const activeProductOrder = payload?.productOrders?.find(item => item.id === productOrderId)
          setActiveProductOrder(activeProductOrder)
          setMonths(inputValues)
          monthsField.onChange(inputValues)
        })
        .catch(() => {})
    }
  }

  const handleDisableButton = () => {
    if (flow === 'classic' && location === 'Online' && !dates?.length) {
      return !parentMonths
    }
    if (flow === 'classic' && location === 'Online' && !!dates?.length) {
      return !dateValue
    }
    if (
      flow === 'classic' &&
      classifications?.find(item => item?.value === classificationValue)?.label?.toLowerCase() ===
        'non - residential' &&
      !dates?.length
    ) {
      return !parentMonths
    }
    if (
      flow === 'classic' &&
      classifications?.find(item => item?.value === classificationValue)?.label?.toLowerCase() ===
        'non - residential' &&
      !!dates?.length
    ) {
      return !dateValue
    }
    if (flow === 'classic' && !activeProductOrder?.location?.airportTransferPrice && !dates?.length) {
      return !parentMonths
    }
    if (flow === 'classic' && !activeProductOrder?.location?.airportTransferPrice && !!dates?.length) {
      return !dateValue
    }
    if (flow === 'classic' && location !== 'Online') {
      return !transferOptions
    }
    if (flow === 'bundle') {
      return !months
    }
  }

  const handleShowSubject = () => {
    if (activeProductOrder?.location?.airportTransferPrice && transferOptions) {
      return true
    } else if (!activeProductOrder?.location?.airportTransferPrice && !dates?.length) {
      return !!parentMonths
    } else if (!activeProductOrder?.location?.airportTransferPrice && !!dates?.length) {
      return !!dateValue
    } else {
      return false
    }
  }

  const onSubmit = data => {
    dispatch(
      validateProductOrder({
        productOrderId,
        callback: () => {
          setStep(flow === 'classic' ? 4 : 6)
          toastId = toast.success(
            <Typography className='toast-text'>You successfully added your programme</Typography>,
            {
              style: {
                minWidth: isSmallScreen ? '100%' : '652px',
                backgroundColor: '#E8F7EE',
                borderRadius: '4px',
                padding: '8px 16px 8px 16px',
                borderLeft: '2px solid #18AB56'
              }
            }
          )

          navigate('/add-another-programme')
        }
      })
    )
  }

  useEffect(() => {
    if (flow === 'classic' && step > 3) {
      dispatch(setStartPathwayScreen('addProgram'))
    }
    if (flow === 'bundle' && step > 5) {
      dispatch(setStartPathwayScreen('addProgram'))
    }
    if ((flow === 'classic' && step <= 3) || (flow === 'bundle' && step <= 5)) {
      dispatch(setStartPathwayScreen(null))
    }
  }, [flow, step, dispatch])

  useEffect(() => {
    return () => {
      if (toastId) {
        toast.dismiss()
      }
    }
  }, [])

  useEffect(() => {
    setShowTimeAlert(activeProductOrder?.package?.classification?.title === 'Group Accredited')

    dispatch(setActiveProductOrderId(activeProductOrder?.id))
    if (activeProductOrder?.id === productOrders[0]?.id) {
      dispatch(setIsMainProductSaved(false))
    }

    return () => {
      dispatch(setActiveProductOrderId(null))
      dispatch(setIsMainProductSaved(true))
    }
  }, [activeProductOrder, productOrders, dispatch])

  return (
    <Wrapper>
      <Typography className='title' component='h1'>
        Start Your Educational <span className='title-gold'>Pathway</span>
      </Typography>
      {isSmallScreen && (
        <>
          <Accordion
            defaultExpanded
            className='accordion'
            disableGutters
            elevation={0}
            sx={{
              '&.Mui-expanded:first-of-type': {
                marginTop: '16px'
              }
            }}
          >
            <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
              <ListIcon />
              <Typography className='accordion-title'>Summary</Typography>
            </AccordionSummary>
            <AccordionDetails className='accordion-details'>
              <Summary />
            </AccordionDetails>
          </Accordion>
          <Box sx={{ marginTop: '16px' }}>
            <Discount titleClassName='bordered-discount' secondVersion />
          </Box>
        </>
      )}
      {!isChangeModalOpen && (
        <Form
          className='form'
          isGap
          isButtonSticky={false}
          onSubmit={handleSubmit(onSubmit)}
          footer={
            <Box className='form-footer-buttons'>
              <CustomButton className='button' type='submit' disabled={handleDisableButton()} isLoading={false}>
                Save
              </CustomButton>
              {step === 0 && !classificationValue && (
                <Typography className='form-footer-text'>Please select programme option</Typography>
              )}
              {step === 1 && classificationValue && (
                <Typography className='form-footer-text'>Please select dates</Typography>
              )}
            </Box>
          }
        >
          <RadioButtonGroup
            label='Select your programme option'
            name='educationModel'
            value={classificationValue}
            groupName='classifications'
            options={classifications ?? []}
            onChange={handleClassificationValueChange}
          />
          {(step >= 1 || classificationValue) && !!dates?.length && !isLoading && (
            <>
              <RadioButtonGroup
                isSmall
                isColumn={isSmallScreen}
                name='dates'
                label='Select your preferred dates'
                value={dateValue}
                groupName='dates'
                options={dates}
                onChange={handleDateValueChange}
              />

              {showTimeAlert && (
                <Box
                  sx={{
                    mt: 2,
                    border: '1px solid #d8ab6d',
                    borderRadius: '8px',
                    padding: '8px 16px 8px 16px'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px'
                    }}
                  >
                    Based in Australasia and can’t see a suitable time? Register your interest in our APAC time zone{' '}
                    <Link
                      target='_blank'
                      href='https://airtable.com/appdvwGYuAmfc8YBE/pag8NcIIdI4DLWi3d/form'
                      sx={{
                        color: '#2d4960',
                        fontWeight: '600',
                        '&:hover': {
                          color: '#19344b'
                        }
                      }}
                    >
                      here
                    </Link>
                  </Typography>
                </Box>
              )}
            </>
          )}
          {(step >= 1 || classificationValue) && !dates?.length && !isLoading && (
            <Input
              value={parentMonths}
              onClick={handleOpenDateModal}
              label={
                <Typography className='calendar-label'>
                  Select your preferred months{' '}
                  <span className='calendar-label-grey'>(we`ll do our best to cater to it)</span>
                </Typography>
              }
              name='parentMonths'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' edge='end'>
                    <CalendarIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          {(step >= 2 || dateValue) &&
            location !== 'Online' &&
            classifications?.find(item => item?.value === classificationValue)?.label?.toLowerCase() !==
              'non - residential' &&
            activeProductOrder?.location?.airportTransferPrice && (
              <RadioButtonGroup
                isSmall
                isMostPopular
                isColumn={isSmallScreen}
                tooltipProps={{
                  tooltip: {
                    sx: {
                      '& .title': {
                        fontSize: '14px',
                        fontWeight: 450,
                        lineHeight: '21px',
                        color: '#1d1d1d'
                      },
                      '& .box': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '8px'
                      },
                      '& .dot': {
                        height: '4px',
                        width: '4px',
                        borderRadius: '50%',
                        background: '#1d1d1d'
                      },
                      '& .item': {
                        fontSize: '14px',
                        fontWeight: 300,
                        lineHeight: '21px',
                        color: '#1d1d1d'
                      }
                    }
                  }
                }}
                modalProps={{
                  '& .title': {
                    fontSize: '14px',
                    fontWeight: 450,
                    lineHeight: '21px',
                    color: '#1d1d1d'
                  },
                  '& .box': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px'
                  },
                  '& .dot': {
                    height: '4px',
                    width: '4px',
                    borderRadius: '50%',
                    background: '#1d1d1d'
                  },
                  '& .item': {
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: '21px',
                    color: '#1d1d1d'
                  }
                }}
                tooltip={
                  <Box>
                    <Typography className='title'>Airport transfers will be from the following destinations</Typography>
                    <Box className='box'>
                      <Box className='dot' />
                      <Typography className='item'>London Heathrow Airport</Typography>
                    </Box>
                    <Box className='box'>
                      <Box className='dot' />
                      <Typography className='item'>Sydney Kingsford Smith Airport</Typography>
                    </Box>
                    <Box className='box'>
                      <Box className='dot' />
                      <Typography className='item'>Toronto Pearson International Airport</Typography>
                    </Box>
                    <Box className='box'>
                      <Box className='dot' />
                      <Typography className='item'>John F. Kennedy International Airport</Typography>
                    </Box>
                    <Box className='box'>
                      <Box className='dot' />
                      <Typography className='item'>San Francisco International Airport </Typography>
                    </Box>
                    <Typography className='item'>
                      Transfer details will be confirmed closer to the flight time.
                    </Typography>
                  </Box>
                }
                label='Would you like support with airport transfer?'
                value={transferOptions}
                groupName='transfer'
                options={transferOptionsData}
                onChange={handleTransferOptionsChange}
              />
            )}
          {flow === 'bundle' && handleShowSubject() && (
            <Select
              onChange={handleSubjectChange}
              label='Select available subject for your Online Research Programme'
              options={subjectsByOrder?.map(item => item.title.replace(/&amp;/g, '&')) ?? []}
              value={subject ?? ''}
              name='subject'
              placeholder='Select subject'
            />
          )}
          {flow === 'bundle' && (step > 4 || subject) && (
            <Input
              value={months}
              onClick={handleOpenDateModal}
              label={
                <Typography className='calendar-label'>
                  Select your preferred month for your Online Research Programme{' '}
                  <span className='calendar-label-grey'>(we`ll do our best to cater to it)</span>
                </Typography>
              }
              name='months'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' edge='end'>
                    <CalendarIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        </Form>
      )}
      <DateModal
        open={isOpenDateModal}
        onClose={() => setIsOpenDateModal(false)}
        onSubmit={handleMonthsSubmit}
        programDate={activeProductOrder?.programDate}
      />

      <Toast />
    </Wrapper>
  )
}

export default StartEducationalPathwayScreen
